<template>
  <div>
    <div>
      <div class="B1">
        <Navigator />
        <p
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="600"
        >
          About <span>Us</span>
        </p>
      </div>

      <div
        class="B2"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="600"
      >
        <div class="B2F1">
          <p class="B2F1_T1">
            Davisind Group is a Mexican company that provides engineering
            solutions to a wide variety of customers from automotive, mining,
            food and beverage industries.
          </p>
          <p class="B2F1_T2">
            <span>DavisInd Group</span> provides complete design of the
            automated systems for Automotive, Mining and food and beverage
            businesses.
          </p>
          <p class="B2F1_T2">
            We developed the world class design of networks, electrical,
            pneumatic and hydraulic systems using the latest technology in the
            automation industry. Additionally, our controls team had developed a
            complete Safe and systems for our customers across North
            America while providing software design, integration, debug, and
            full support services on-site.
          </p>
          <p class="B2F1_T2">
            Our in-depth expertise in the field of robotics enables us to
            provide professional engineering service for startup, debugging and
            field support at our customers’ facilities across North America.
          </p>
        </div>
        <div class="B2F2">
          <img class="showM" src="../assets/about/DI_MV.png" />
          <img class="showD" src="../assets/about/DI_DT.png" />
        </div>
      </div>

      <div class="B3">
        <div class="B3F1">
          <section
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="600"
          >
            <div class="Line_Mission"></div>
            <div class="B3F1_F1">
              <img src="../assets/about/Rocket_MV.svg" />
              <p>Mission <span>Statement</span></p>
            </div>
            <p class="B3F1_F2">
              To develop industrial automation and technology solutions of the
              highest quality, providing our clients with unique solutions that
              adapt to meet the demands of their projects.
            </p>
          </section>
        </div>
        <div class="B3F2">
          <section
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="600"
            data-aos-delay="100"
          >
            <div class="Line_Vision"></div>
            <div class="B3F2_F1">
              <img src="../assets/about/Bulb_MV.svg" />
              <p>Vision <span>Statement</span></p>
            </div>
            <p class="B3F2_F2">
              To become a leading Technology company in system integrations,
              industrial process development, and technological innovation
              worldwide.
            </p>
          </section>
        </div>
      </div>

      <div class="B4">
        <div
          class="B4F1"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="600"
        >
          <img class="showM" src="../assets/about/Team_MV.png" />
          <img class="showD" src="../assets/about/Team_DT.png" />
          <div class="B4F1_F2">
            <div class="Line_Team"></div>
            <p class="B4F1_F2T1">Our <span>team</span></p>
            <p class="B4F1_F2T2 w1">
              We have one goal, to achieve maximum efficiency of our staff. How
              do we do that? By assigning them tasks that best suit their skills
              for optimum results.
            </p>
            <p class="B4F1_F2T2">
              Striving to be number one in technological innovation and
              industrial automation.
            </p>
          </div>
        </div>
        <div
          class="B4F2"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="600"
        >
          <img class="imgvalu" src="../assets/sellovalores.png" alt="Values">
          <img class="showM" src="../assets/about/Company_MV.png" />
          <img class="showD" src="../assets/about/Company_DT.png" />
          <div class="B4F2_F2">
            <div class="Line_Company"></div>
            <p class="B4F2_F2T1">Our company <span>values</span></p>
            <p class="B4F2_F2T2">
              The most valuable asset we have is our staff. We develop
              initiatives aimed to promote their:
            </p>
            <ul class="B4F2_F2T3">
              <li>Professional growth in our workplace.</li>
              <li class="OnlyD">
                Their integration and commitment to society.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigator from "../components/Navigator";
import Footer from "../components/Footer.vue";
export default {
  name: "About",
  components: {
    Navigator,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.B1 {
  width: 100%;
  height: 109.34579439252336vw;
  background-image: url("../assets/about/Header_MV.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.B1 p {
  font-size: 8.177570093457943vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  color: var(--color-5);
  margin: 0vw;
  display: flex;
  justify-content: center;
  padding-top: 62.85vw;
  display: flow-root;
  text-align: center;
}
.B1 span {
  color: var(--color-2);
}

p.B2F1_T1 {
  display: flex;
  width: 72.89719626168224vw;
  font-size: 3.7383177570093453vw;
  font-weight: 400;
  line-height: 5.33411214953271vw;
  font-family: var(--MOSB_Regular);
  color: var(--color-1);
  margin: auto;
  margin-bottom: 12vw;
  margin-top: 17.289719626168225vw;
}
p.B2F1_T2 {
  display: flex;
  width: 72.89719626168224vw;
  font-size: 3.774vw;
  font-weight: 400;
  line-height: 5.33411214953271vw;
  font-family: var(--Open_Sans);
  color: var(--color-3);
  margin: auto;
  margin-bottom: 6vw;
  display: flow-root;
}
.B2F1_T2 span {
  font-family: var(--Open_Sans);
  font-weight: 600;
}

.B2F2 {
  display: flex;
  justify-content: center;
}
.B2F2 img {
  width: 84.11214953271028vw;
  height: 84.11214953271028vw;
}
.showD {
  display: none;
}

.B3F1 {
  width: 100%;
  height: 104.245vw;
  background-image: url("../assets/about/Mission_MV.png");
  padding-top: 24.065420560747665vw;
  background-repeat: no-repeat;
  background-size: cover;
}
.Line_Mission {
  width: 6.775700934579438vw;
  height: 0.5vw;
  background-color: var(--color-1);
  margin-left: 33.8785046728972vw;
}
.B3F1_F1 {
  display: flex;
  align-items: center;
  margin-left: 10.514018691588785vw;
  margin-top: 8.644859813084112vw;
}
.B3F1_F1 img {
  width: 15.42056074766355vw;
  height: 15.42056074766355vw;
  margin-right: 7.476635514018691vw;
}
.B3F1_F1 p {
  width: 27vw;
  font-size: 5.607476635514018vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  color: var(--color-5);
  margin: 0vw;
}
.B3F1_F1 span {
  color: var(--color-1);
}
p.B3F1_F2 {
  width: 41.822429906542055vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  line-height: 4.995327102803738vw;
  font-family: var(--OpenSans);
  color: var(--color-5);
  margin: 0vw;
  margin-left: 33.8785046728972vw;
  margin-top: 15.094vw;
}

.B3F2 {
  width: 100%;
  height: 104.245vw;
  background-image: url("../assets/about/Vision_MV.png");
  padding-top: 29.672897196261683vw;
  background-repeat: no-repeat;
  background-size: cover;
}
.Line_Vision {
  width: 6.775700934579438vw;
  height: 0.5vw;
  background-color: var(--color-5);
  margin-left: 35.280373831775705vw;
}
.B3F2_F1 {
  display: flex;
  align-items: center;
  margin-left: 12.850467289719624vw;
  margin-top: 6.074766355140187vw;
}
.B3F2_F1 img {
  width: 15.654205607476634vw;
  height: 16.355140186915886vw;
  margin-right: 6.308411214953271vw;
}
.B3F2_F1 p {
  width: 27vw;
  font-size: 5.607476635514018vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  color: var(--color-2);
  margin: 0vw;
}
.B3F2_F1 span {
  color: var(--color-5);
}
p.B3F2_F2 {
  width: 41.822429906542055vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  line-height: 4.995327102803738vw;
  font-family: var(--OpenSans);
  color: var(--color-5);
  margin: 0vw;
  margin-left: 33.8785046728972vw;
  margin-top: 15.094vw;
}

.B4F1 img {
  width: 100%;
  height: 56.54205607476636vw;
  margin-bottom: 20.327102803738317vw;
}
.B4F1_F2 {
  margin-left: 14.485981308411214vw;
  margin-bottom: 30.887850467289724vw;
}
.Line_Team {
  width: 7.009345794392523vw;
  height: 0.5vw;
  background-color: var(--color-1);
}
p.B4F1_F2T1 {
  font-size: 5.607476635514018vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  color: var(--color-2);
  margin: 0vw;
  margin-bottom: 10vw;
  margin-top: 7.476635514018691vw;
}
.B4F1_F2T1 span {
  color: var(--color-1);
}
p.B4F1_F2T2 {
  width: 68vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  line-height: 4.995327102803738vw;
  font-family: var(--OpenSans);
  color: var(--color-3);
  margin: 0vw;
  margin-bottom: 6vw;
}
p.B4F1_F2T2.w1{
    width: 71vw;
}
.B4F2 img {
  width: 100%;
  height: 56.54205607476636vw;
  margin-bottom: 21.261682242990652vw;
}
.B4F2_F2 {
  margin-left: 14.485981308411214vw;
  margin-bottom: 40.887850467289724vw;
}
.Line_Company {
  width: 7.009345794392523vw;
  height: 0.5vw;
  background-color: var(--color-1);
}
p.B4F2_F2T1 {
  font-size: 5.607476635514018vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  color: var(--color-2);
  margin: 0vw;
  margin-bottom: 10vw;
  margin-top: 7.476635514018691vw;
}
.B4F2_F2T1 span {
  color: var(--color-1);
}
p.B4F2_F2T2 {
  width: 71vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  line-height: 4.995327102803738vw;
  font-family: var(--OpenSans);
  color: var(--color-3);
  margin: 0vw;
  margin-bottom: 6vw;
}
ul.B4F2_F2T3 {
  padding-left: 6vw;
  margin: 0vw;
}
.B4F2_F2T3 li {
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  line-height: 4.995327102803738vw;
  font-family: var(--OpenSans);
  color: var(--color-3);
  margin: 0vw;
}

@media (min-width: 768px) {
  .B1 {
    height: 25.937500000000004vw;
    background-image: url("../assets/about/Header_DT.png");
  }
  .B1 p {
    font-size: 3.6458333333333335vw;
    padding-top: 16.615vw;
    justify-content: initial;
    margin-left: 7.708333333333334vw;
    text-align: initial;
  }
  .B2 {
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;
  }
  .B2F2 img {
    width: 39.0625vw;
    height: 39.0625vw;
    margin-top: 10.989583333333334vw;
    margin-bottom: 23.177083333333336vw;
  }
  .showD {
    display: block;
  }
  .showM {
    display: none;
  }
  .B2F1 {
    margin-left: 6.406249999999999vw;
  }
  p.B2F1_T1 {
    width: 24.6vw;
    font-size: 1.25vw;
    line-height: 1.7833333333333332vw;
    margin-bottom: 4vw;
    margin-top: 16.302083333333332vw;
    margin-left: 0vw;
  }
  p.B2F1_T2 {
    width: 25.989583333333332vw;
    font-size: 0.9375vw;
    line-height: 1.4312500000000001vw;
    margin-bottom: 1.8vw;
  }

  .B3 {
    display: flex;
  }
  .B3F1 {
    width: 50%;
    height: 56.56250000000001vw;
    background-image: url("../assets/about/Mission_DT.png");
    padding-top: 24.065420560747665vw;
  }
  .Line_Mission {
    width: 2.604166666666667vw;
    height: 0.1vw;
    margin-left: 18.177083333333332vw;
  }
  .B3F1_F1 img {
    width: 6.197916666666667vw;
    height: 6.197916666666667vw;
    margin-right: 2.4479166666666665vw;
  }
  .B3F1_F1 {
    margin-left: 9.479166666666666vw;
    margin-top: 4.427083333333334vw;
  }
  .B3F1_F1 p {
    width: 16vw;
    font-size: 2.604166666666667vw;
  }
  p.B3F1_F2 {
    width: 18.82243vw;
    font-size: 0.9375vw;
    line-height: 1.4312500000000001vw;
    margin-left: 18.229166666666664vw;
    margin-top: 3.229166666666667vw;
  }

  .B3F2 {
    width: 50%;
    height: 56.56250000000001vw;
    background-image: url("../assets/about/Vision_DT.png");
    padding-top: 24.065420560747665vw;
  }
  .Line_Vision {
    width: 2.604166666666667vw;
    height: 0.1vw;
    margin-left: 18.958333333333332vw;
  }
  .B3F2_F1 img {
    width: 6.197916666666667vw;
    height: 6.197916666666667vw;
    margin-right: 2.8125vw;
  }
  .B3F2_F1 {
    margin-left: 10vw;
    margin-top: 3.177083333333333vw;
  }
  .B3F2_F1 p {
    width: 16vw;
    font-size: 2.604166666666667vw;
  }
  p.B3F2_F2 {
    width: 18.82243vw;
    font-size: 0.9375vw;
    line-height: 1.4312500000000001vw;
    margin-left: 19.010416666666664vw;
    margin-top: 3.3333333333333335vw;
  }

  .B4F1 {
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-end;
    margin-top: 21.927083333333332vw;
    margin-bottom: 22.239583333333332vw;
    align-items: center;
  }
  .B4F1 img {
    width: 44.479166666666664vw;
    height: 35.104166666666664vw;
    margin-bottom: 0vw;
  }
  .B4F1_F2 {
    margin-left: 11.041666666666666vw;
    margin-bottom: 0vw;
    margin-right: 14.374999999999998vw;
  }
  .Line_Team {
    width: 2.604166666666667vw;
    height: 0.1vw;
  }
  p.B4F1_F2T1 {
    font-size: 2.604166666666667vw;
    margin-bottom: 4.270833333333333vw;
    margin-top: 3.28125vw;
  }
  p.B4F1_F2T2 {
    width: 20vw;
    font-size: 0.9375vw;
    line-height: 1.4312500000000001vw;
    margin-bottom: 2vw;
  }
  p.B4F1_F2T2.w1{
    width: 21vw;
  }

  .B4F2 {
    display: flex;
    margin-bottom: 17.8125vw;
    align-items: center;
  }
  .B4F2 img {
    width: 44.479166666666664vw;
    height: 35.104166666666664vw;
    margin-bottom: 0vw;
    margin-left: 7.447916666666667vw;
  }
  .B4F2_F2 {
    margin-left: 12.65625vw;
    margin-bottom: 0vw;
  }
  .Line_Company {
    width: 2.604166666666667vw;
    height: 0.1vw;
  }
  p.B4F2_F2T1 {
    width: 14vw;
    font-size: 2.604166666666667vw;
    margin-bottom: 4.270833333333333vw;
    margin-top: 3.28125vw;
  }
  p.B4F2_F2T2 {
    width: 21vw;
    font-size: 0.9375vw;
    line-height: 1.4312500000000001vw;
    margin-bottom: 1.5vw;
  }
  ul.B4F2_F2T3 {
    padding-left: 2vw;
  }
  li.OnlyD {
    width: 16vw;
  }
  .B4F2_F2T3 li {
    font-size: 0.9375vw;
    line-height: 1.4312500000000001vw;
  }
  img.imgvalu {
    width: 12% !important;
    height: auto !important;
    position: absolute;
    left: 40%;
    top: -22%;
  }
}
</style>